<script setup lang="ts">
import {
  VeeFormField,
  FormItem,
  FormControl,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { type HTMLAttributes } from 'vue';

interface Props {
  label?: string;
  name: string;
  class?: HTMLAttributes['class'];
  type?: string;
  placeholder?: string;
  id?: string;
  // Fixes optional fields by setting them to undefined when they are empty
  optionalFix?: boolean;
  autocomplete?: string;
  hideMessage?: boolean;
  disabled?: boolean;
}

const props = defineProps<Props>();
</script>
<template>
  <VeeFormField v-slot="slotProps" :name="name">
    <FormItem>
      <FormLabel v-if="label">{{ label }}</FormLabel>
      <FormControl>
        <slot
          v-bind="{
            ...slotProps,
            componentField: {
              disabled: props.disabled,
              ...slotProps.componentField,
              invalid:
                !slotProps.meta.valid &&
                (slotProps.meta.dirty || slotProps.meta.validated),
            },
          }">
          <Input
            v-bind="slotProps.componentField"
            :id="props.id ?? name"
            :disabled="disabled"
            :class="[
              props.class,
              {
                'text-destructive border-destructive':
                  !slotProps.meta.valid &&
                  (slotProps.meta.dirty || slotProps.meta.validated),
              },
            ]"
            :type="props.type"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            @blur="
              () => {
                if (props.optionalFix && slotProps.value === '') {
                  slotProps.setValue(undefined);
                }
              }
            " />
        </slot>
      </FormControl>
      <FormMessage v-if="!hideMessage" />
    </FormItem>
  </VeeFormField>
</template>
